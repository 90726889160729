import { sendDataLayerEvent } from "./dataLayer";

const links = [
  "header-create-thread",
  "header-whats-new",
  "header-forum-listing",
  "header-account-details",
  "header-site-navigation",
];

const kebabLinks = [
  "kebab-menu-whats-new-link",
  "kebab-menu-forum-listing-link",
  "kebab-menu-marketplace-link",
  "kebab-menu-showcase-link",
  "kebab-menu-media-link",
  "kebab-menu-search-link",
  "kebab-menu-members-link",
  "kebab-menu-faq-link",
];

const accountLinks = [
  "account-details-my-profile",
  "account-details-business-dashboard",
  "account-details-following",
  "account-details-alerts",
  "account-details-conversations",
  "account-details-bookmarks",
  "account-details-my-showcase",
  "account-details-account-settings",
  "account-details-grow-your-business",
  "account-details-premium-subscription",
  "account-details-log-out",
];

const kebabOpenLink = document.getElementById("header-site-navigation");
const menuThemeLinks = document.querySelectorAll<HTMLAnchorElement>(
  ".kebab-menu-theme-link"
);

bindGTMEventsToLinks(links);
listenForAccountDetailsLoad();

kebabOpenLink?.addEventListener(
  "click",
  () => {
    bindGTMEventsToLinks(kebabLinks);
  },
  { once: true }
);

menuThemeLinks.forEach((i) =>
  i.addEventListener("click", () =>
    pushGTMEvent(`kebab menu theme mode link (${i.textContent?.trim()})`)
  )
);

function listenForAccountDetailsLoad() {
  // Get container that will be populated with account detail items
  const targetNode = document.getElementsByClassName("js-visitorMenuBody")[0];

  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      // Check that account detail items have been added via Ajax call
      if (mutation.addedNodes && mutation.addedNodes.length > 0) {
        bindGTMEventsToLinks(accountLinks);
        observer.disconnect();
      }
    });
  });

  observer.observe(targetNode, config);
}

function bindGTMEventsToLinks(linksArray: string[]) {
  linksArray.forEach((elementId) => {
    const link = document.getElementById(elementId);

    if (link) {
      link.addEventListener("click", () => {
        const itemName = elementId.replace(/-/g, " ");
        pushGTMEvent(itemName);
      });
    }
  });
}

function pushGTMEvent(itemName: string) {
  sendDataLayerEvent("HeaderMenuClicked", {
    HeaderMenuClickedItem: itemName,
  });
}
